
















































































/* tslint:disable:no-console */
import AProgressBar from "@/components/controls/AProgressBar.vue";
import AReportRequestConfigurationDialog from "@/components/dialogs/AReportRequestConfigurationDialog.vue";

import AAuditItemsListFilter from "@/components/widgets/AAuditItemsListFilter.vue";
import AAuditOverview from "@/components/widgets/AAuditOverview.vue";
import { contentLanguage } from "@/plugins/ContentTranslation";
import { dialogRoute, DIALOG_NAMES, ROUTE_NAMES } from "@/routenames";
import { api as appApi } from "@/store/modules/app";
import { api } from "@/store/modules/audit";
import { AuditProgress } from "@/store/modules/audit/types";
import { api as confApi } from "@/store/modules/configuration";
import requestPdfReport, {
  PdfChecklistRequest,
} from "@/utils/requestPdfReport";
import { ChecklistPageConfig } from "@auditcloud/shared/lib/schemas";
import { AuditChecklistExportData } from "@auditcloud/shared/lib/types/AuditChecklistExport";
import { AuditItemCategoryMap } from "@auditcloud/shared/lib/types/AuditItemCategory";
import {
  WorkflowAction,
  WorkflowActionIds,
  WorkflowActionPayload,
} from "@auditcloud/shared/lib/types/WorkflowActions";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { formatAuditName } from "@auditcloud/shared/lib/utils/formatting/audits";
import { TodoMap } from "@auditcloud/shared/lib/utils/type-guards";
import { cloneDeep, Dictionary } from "lodash";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { createError } from "../utils/Errors";

@Component({
  components: {
    AAuditOverview,
    AAuditItemsListFilter,
    AProgressBar,
    AReportRequestConfigurationDialog,
  },
})
export default class AuditExecution extends Vue {
  linkPopup = false;
  remoteLink: string | null = null;
  linkCopyHint: string | null = null;
  isIntersecting: boolean = false;
  isDownloadDialogOpen: boolean = false;
  pdfRequest: null | PdfChecklistRequest = null;

  @Getter(api.getters.getAuditChecklistExportData, { namespace: api.namespace })
  checklistExportData!: AuditChecklistExportData;

  @Getter(api.getters.getChecklistPages, { namespace: api.namespace })
  checklistPages!: ChecklistPageConfig[];

  get next() {
    return {
      name: ROUTE_NAMES.AUDITWRAPUP,
      params: {
        auditId: this.auditId,
      },
    };
  }

  get prev() {
    return {
      name: ROUTE_NAMES.AUDITPREPARATION,
      params: {
        auditId: this.auditId,
      },
    };
  }

  get filenamePdf() {
    return `Report_${moment().format("YYYY-MM-DD")}_${formatAuditName(
      this.currentAudit
    )}.pdf`;
    // return "Report.pdf";
  }

  openIntermediateResultDialog() {
    const routeName = this.$route.name ?? "error";
    const auditId = this.auditId;

    this.$router.push({
      name: dialogRoute(routeName, DIALOG_NAMES.INTERMEDIATE_RESULT_DIALOG),
      params: {
        auditId,
      },
    });
  }

  get pdfGenerator() {
    console.log("Checklist PDF Request", this.checklistExportData);
    if (this.pdfRequest === null) {
      return () => Promise.reject(createError("Request Data is null"));
    }
    if (this.pdfRequest.pages.length === 0) {
      return () => Promise.reject(createError("No report pages configured"));
    }
    return requestPdfReport(this.pdfRequest);
  }

  //missingDefault
  @Prop({
    type: String,
  })
  readonly auditId!: string;

  @Prop({
    type: Object,
  })
  readonly audit!: TodoMap;

  /*onIntersect(entries: any, observer: any) {
    console.log("Intersection", entries, entries[0].isIntersecting);
    // More information about these options
    // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.isIntersecting = entries[0].isIntersecting;
  }*/

  @Getter(api.getters.getAuditCategoryMapping, { namespace: api.namespace })
  categoryMapping!: AuditItemCategoryMap;

  @Getter(api.getters.getAuditMetadata, { namespace: api.namespace })
  currentAudit!: any;

  // Get all active audititems for export
  @Getter(api.getters.getAuditProgress, { namespace: api.namespace })
  currentAuditProgress!: AuditProgress;

  @Getter(confApi.getters.customerLogo, { namespace: api.namespace })
  customerLogo!: string | null;

  @Getter(confApi.getters.configurableChecklistFeatureEnabled, {
    namespace: confApi.namespace,
  })
  configurableChecklistFeatureEnabled!: boolean;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  setLoading!: (v: boolean) => Promise<any>;

  @Action(appApi.actions.setLoading, { namespace: appApi.namespace })
  addStatus!: (payload: { message: string; type: "error" }) => Promise<any>;

  @Getter(api.getters.getAuditPermissions, {
    namespace: api.namespace,
  })
  permissions!: AuditPermissions;

  @Getter(confApi.getters.selfAssessmentFeatureEnabled, {
    namespace: confApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  get writePermission() {
    return this.permissions.write;
  }
  downloadBlob: null | Blob = null;
  blobUrl: string | null = null;

  onScroll(e: any) {
    if (
      (this.$vuetify.breakpoint.mobile && window.pageYOffset > 64) ||
      window.pageYOffset > 70
    ) {
      this.isIntersecting = true;
    } else {
      this.isIntersecting = false;
    }
  }

  @Getter(api.getters.getActionsPerView, {
    namespace: api.namespace,
  })
  actionsPerView!: (viewname: string) => string[];

  @Getter(api.getters.getSelfAssessmentIncluded, {
    namespace: api.namespace,
  })
  selfAssessmentIncluded!: boolean;

  mounted() {
    this.$vuetify.goTo(0);

    const actionMap: { [id: string]: WorkflowAction } = {
      [WorkflowActionIds.CHECKLIST]: {
        id: WorkflowActionIds.CHECKLIST,
        text: this.$t("views.audit_execution.export").toString(),
        icon: "notes",
        handler: () => {
          this.pdfRequest = {
            templateName: "checklist",
            language: contentLanguage(),
            pages: cloneDeep(this.checklistPages),
            checklistData: this.checklistExportData,
          };
          if (this.configurableChecklistFeatureEnabled) {
            this.isDownloadDialogOpen = true;
          } else {
            this.downloadPdf();
          }
        },
      },
      [WorkflowActionIds.SHOW_INTERMEDIATE_RESULT]: {
        id: WorkflowActionIds.SHOW_INTERMEDIATE_RESULT,
        text: this.$t("views.audit.intermediate_result").toString(),
        icon: "mdi-chart-tree",
        handler: () => {
          this.openIntermediateResultDialog();
        },
      },
    };

    if (this.selfAssessmentFeatureEnabled && this.selfAssessmentIncluded) {
      const v1Handler = () => {
        const newRoute = {
          name: this.$route.name ?? "error",
          params: {
            ...this.$route.params,
            dialogName: DIALOG_NAMES.ACTIVATE_SELF_ASSESSMENT,
          },
        };
        this.$router.push(newRoute);
      };

      actionMap[WorkflowActionIds["ACTIVATE_SELF_ASSESSMENT"]] = {
        id: WorkflowActionIds["ACTIVATE_SELF_ASSESSMENT"],
        text: this.$t(
          "components.widgets.sidebar.self_assessment.manage_self_assessment"
        ).toString(),
        icon: "mdi-remote-desktop",
        handler: v1Handler,
      };
    }

    const actionsList = this.actionsPerView("execution")
      .filter(id => id in actionMap)
      .map(id => actionMap[id]);

    const primary = actionsList.length > 0 ? { primary: actionsList[0] } : {};
    const secondary =
      actionsList.length > 1
        ? {
            secondary: actionsList.slice(1),
          }
        : {};
    const actions: WorkflowActionPayload = { ...primary, ...secondary };

    this.$emit("actions", actions);
  }

  //  PDF Generation:
  downloadPdf() {
    this.isDownloadDialogOpen = false;
    this.setLoading(true);
    const self = this;
    this.pdfGenerator()
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        self.downloadBlob = blob;
        self.blobUrl = url;

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = self.blobUrl;
        a.download = self.filenamePdf;
        a.click();
        document.body.removeChild(a);
        self.setLoading(false);
      })
      .catch(err => {
        console.log("Download failed", err);
        self.addStatus({ message: "Download failed", type: "error" });
        self.setLoading(false);
      });
  }

  copyLink() {
    const element = (this.$refs.remoteLink as Vue).$refs
      .input as HTMLInputElement;
    console.log("copyLink", element);

    element.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.linkCopyHint = "Link kopiert";
        window.setTimeout(() => {
          this.linkCopyHint = null;
        }, 3000);
      }
    } catch (err) {
      alert("Oops, unable to copy");
    }

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }
}
