import axios from "axios";
import { endpointUrlPdf } from "@/utils/HttpApi";
import {
  assertType,
  typeIsGeneratePdfRequest,
} from "@auditcloud/shared/lib/schemas/type-guards";
import { AuditReportExportData } from "@auditcloud/shared/lib/types/AuditReportExport";
import { AuditChecklistExportData } from "@auditcloud/shared/lib/types/AuditChecklistExport";
import {
  ChecklistPageConfig,
  ReportPageConfig,
} from "@auditcloud/shared/lib/schemas";

export type PdfReportRequest = {
  language: string;
  templateName: "report";
  pages: ReportPageConfig[];
  reportData: AuditReportExportData;
};
export type PdfChecklistRequest = {
  language: string;
  templateName: "checklist";
  pages: ChecklistPageConfig[];
  checklistData: AuditChecklistExportData;
};

const requestPdfReport = (data: PdfReportRequest | PdfChecklistRequest) => {
  console.log("requestPdfReport: template:", data.templateName);
  assertType(data, typeIsGeneratePdfRequest);
  return () => {
    console.log("requestPdfReport: data:", data);
    return new Promise<Blob>((resolve, reject) => {
      axios({
        method: "post",
        url: endpointUrlPdf(),
        responseType: "blob",
        data,
      })
        .then(response => {
          if (
            response.status === 200 &&
            response.headers["content-type"] === "application/pdf"
          ) {
            const dataBlob = response.data;
            console.assert(
              dataBlob instanceof Blob,
              `excpect blob return type `,
              dataBlob
            );
            resolve(dataBlob);
          } else {
            reject({ Error: "Download failed", response });
          }
        })
        .catch(reject);
    });
  };
};

export default requestPdfReport;
