








































import { PdfReportRequest } from "@/utils/requestPdfReport";
import { ReportPageConfig } from "@auditcloud/shared/lib/schemas";
import { getDefaultPageTitle } from "@auditcloud/shared/lib/utils/reportHelper";

import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class AReportRequestConfigurationDialog extends Vue {
  isOpen: boolean = true;
  @Prop({
    type: Object as PropType<PdfReportRequest>,
  })
  value!: PdfReportRequest;

  pageTitle(page: ReportPageConfig): string {
    if (page.title) {
      return this.$ft(page.title).toString();
    }
    return this.$ft(getDefaultPageTitle(page));
  }

  get isSelectionEmpty(): boolean {
    return !this.value.pages.some(p => p.isIncluded);
  }
}
